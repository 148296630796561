<template>
  <div class="page bg-white" id="reorganize">
    <div class="pageHeight">
      <el-steps v-if="butType == 0" :active="step" align-center style="margin-bottom: 20px">
        <el-step title="基本信息"></el-step>
        <el-step title="相关藏品"></el-step>
        <el-step title="预览"></el-step>
      </el-steps>

      <div v-show="step == 0 || step == 2 || step == 99">
        <el-form :model="inputForm" ref="inputForm" :rules="rules" label-width="130px"
                 size="small">
          <el-row>
            <el-col :span="11">
              <el-form-item label="保护事件名称" prop="protectEventName">
                <el-input
                    v-model.trim="inputForm.protectEventName"
                    placeholder="请输入保护事件名称(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护级别" prop="protectionLevel">
                <el-select
                    v-model="inputForm.protectionLevel"
                    clearable
                    placeholder="请选择保护级别"
                    :disabled="inputType"
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('protection_level')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护周期" prop="mttr" class="adsfaads">
                <el-date-picker
                    v-model="inputForm.mttr"
                    type="daterange"
                    style="width: 100%;"
                    :disabled="inputType"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护类型" prop="protectionType">
                <el-select
                    v-model="inputForm.protectionType"
                    clearable
                    placeholder="请选择保护类型"
                    :disabled="inputType"
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('protection_type')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护使用材料" prop="protectiveMaterial">
                <el-input
                    v-model.trim="inputForm.protectiveMaterial"
                    placeholder="请输入保护使用材料(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护设备" prop="protectiveEquipment">
                <el-input
                    v-model.trim="inputForm.protectiveEquipment"
                    placeholder="请输入保护设备(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护费用（元）" prop="protectionCost">
                <el-input
                    v-model.trim.number="inputForm.protectionCost"
                    placeholder="请输入保护费用"
                    @input="jiage"
                    @blur="save2Data"
                    maxlength="10"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护人员" prop="protectionPersonnel">
                <el-input
                    v-model.trim="inputForm.protectionPersonnel"
                    placeholder="请输入保护人员(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="保护具体措施" prop="specificProtectionMeasures">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.specificProtectionMeasures" maxlength="200"
                          placeholder="请输入保护具体措施(限200字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="备注" prop="remark">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.remark" maxlength="100"
                          placeholder="其它需要特别说明的事项或信息(限100字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <div v-show="step == 99">
              <el-col :span="24">
                <div class="minTitle">保护图片</div>
              </el-col>
              <el-col :span="22">
                <el-form-item label-width="0">
                  <div class="flex_b_t">
                    <div style="width: 140px;text-align: right;margin-top: 34px">保护前图片</div>
                    <div class="flex_b_t" style="flex: 1">
                      <div @click="current1 == 1 ? '' : getMountedPictures1(1)"
                           :class="['paging', 'el-icon-arrow-left',current1 == 1 ? 'prohibit' : '']"></div>
                      <div style="flex: 1;flex-wrap: wrap" class="flex_l_c">
                        <template v-if="photoList1.length">
                          <div v-for="(item,index) in photoList1" :key="index" class="itemStyle">
                            <el-image
                                style="width: 100%; height: 100px;margin-bottom: -12px;cursor: pointer"
                                :src="item.netUrl"
                                @click="previewPdf(item.netUrl)"
                                :fit="'cover'"></el-image>
                            <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
                              <div class="omit itemStyleName">{{ item.fileName }}</div>
                            </el-tooltip>
                            <div class="text_center">
                              <el-button size="mini" type="text" @click="downFiile(item.netUrl,item.fileName)">
                                下载
                              </el-button>
                            </div>
                          </div>
                        </template>
                        <div v-else class="text_center" style="flex: 1;margin-top: 32px">暂无图片</div>
                      </div>
                      <div @click="current1 * size < total1 ? getMountedPictures1(2) : ''"
                           :class="['paging', 'el-icon-arrow-right', current1 * size < total1 ? '' : 'prohibit' ]"></div>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label-width="0">
                  <div class="flex_b_t">
                    <div style="width: 140px;text-align: right;margin-top: 34px">保护中图片</div>
                    <div class="flex_b_t" style="flex: 1">
                      <div @click="current2 == 1 ? '' : getMountedPictures2(1)"
                           :class="['paging', 'el-icon-arrow-left',current2 == 1 ? 'prohibit' : '']"></div>
                      <div style="flex: 1;flex-wrap: wrap" class="flex_l_c">
                        <template v-if="photoList2.length">
                          <div v-for="(item,index) in photoList2" :key="index" class="itemStyle">
                            <el-image
                                style="width: 100%; height: 100px;margin-bottom: -12px;cursor: pointer"
                                :src="item.netUrl"
                                @click="previewPdf(item.netUrl)"
                                :fit="'cover'"></el-image>
                            <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
                              <div class="omit itemStyleName">{{ item.fileName }}</div>
                            </el-tooltip>
                            <div class="text_center">
                              <el-button size="mini" type="text" @click="downFiile(item.netUrl,item.fileName)">
                                下载
                              </el-button>
                            </div>
                          </div>
                        </template>
                        <div v-else class="text_center" style="flex: 1;margin-top: 32px">暂无图片</div>
                      </div>
                      <div @click="current2 * size < total2 ? getMountedPictures2(2) : ''"
                           :class="['paging', 'el-icon-arrow-right', current2 * size < total2 ? '' : 'prohibit' ]"></div>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label-width="0">
                  <div class="flex_b_t">
                    <div style="width: 140px;text-align: right;margin-top: 34px">保护后图片</div>
                    <div class="flex_b_t" style="flex: 1">
                      <div @click="current3 == 1 ? '' : getMountedPictures3(1)"
                           :class="['paging', 'el-icon-arrow-left',current3 == 1 ? 'prohibit' : '']"></div>
                      <div style="flex: 1;flex-wrap: wrap" class="flex_l_c">
                        <template v-if="photoList3.length">
                          <div v-for="(item,index) in photoList3" :key="index" class="itemStyle">
                            <el-image
                                style="width: 100%; height: 100px;margin-bottom: -12px;cursor: pointer"
                                :src="item.netUrl"
                                @click="previewPdf(item.netUrl)"
                                :fit="'cover'"></el-image>
                            <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
                              <div class="omit itemStyleName">{{ item.fileName }}</div>
                            </el-tooltip>
                            <div class="text_center">
                              <el-button size="mini" type="text" @click="downFiile(item.netUrl,item.fileName)">
                                下载
                              </el-button>
                            </div>
                          </div>
                        </template>
                        <div v-else class="text_center" style="flex: 1;margin-top: 32px">暂无图片</div>
                      </div>
                      <div @click="current3 * size < total3 ? getMountedPictures3(2) : ''"
                           :class="['paging', 'el-icon-arrow-right', current3 * size < total3 ? '' : 'prohibit' ]"></div>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </div>
          </el-row>
        </el-form>
      </div>

      <div v-if="step == 1 || step == 2">
        <div v-if="step != 2">
          <el-button size="small" type="primary" @click="addDataList(1,generalList)" icon="el-icon-plus">
            总账藏品
          </el-button>
          <el-button size="small" type="primary" @click="addDataList(2,auxiliaryList)" icon="el-icon-plus">
            辅助帐藏品
          </el-button>
          <el-button size="small" type="primary" @click="addDataList(3,immovableList)" icon="el-icon-plus">
            不可移动文物
          </el-button>
        </div>

        <div class="flex_b_c">
          <div>
            <div class="minTitle">总账藏品清单</div>
            <div style="margin-left: 25px">已选{{ generalList.length }}件藏品</div>
          </div>
          <div class="text_right" v-if="step != 2">
            <el-button icon="el-icon-delete" size="small" type="danger"
                       @click="deleteListData(generalList,selectGeneralList,'0')">批量删除
            </el-button>
          </div>
          <!--                <el-button size="small" v-if="this.inputForm.processState == 3" @click="exportData(0)">批量导出</el-button>-->
        </div>
        <el-table
            :data="generalList"
            size="small"
            style="padding-left: 20px"
            height="300px"
            class="table"
            ref="multipleTable0"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeGenera"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="picMasterViewUrl" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.identified == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="collectionLevel" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
            </template>
          </el-table-column>
        </el-table>

        <div class="flex_b_c">
          <div>
            <div class="minTitle">辅助账藏品清单</div>
            <div style="margin-left: 25px">已选{{ auxiliaryList.length }}件藏品</div>
          </div>
          <div class="text_right" v-if="step != 2">
            <el-button icon="el-icon-delete" size="small" type="danger"
                       @click="deleteListData(auxiliaryList,selectAuxiliaryList,'1')">批量删除
            </el-button>
          </div>
          <!--                <el-button size="small" v-if="this.inputForm.processState == 3" @click="exportData(1)">批量导出</el-button>-->
        </div>
        <el-table
            :data="auxiliaryList"
            size="small"
            style="padding-left: 20px"
            height="300px"
            class="table"
            ref="multipleTable1"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeAuxiliary"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="picMasterViewUrl" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.identified == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="collectionLevel" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
            </template>
          </el-table-column>
        </el-table>

        <div class="flex_b_c">
          <div>
            <div class="minTitle">不可移动文物清单</div>
            <div style="margin-left: 25px">已选{{ immovableList.length }}件藏品</div>
            <!--                <el-button size="small" v-if="this.inputForm.processState == 3" @click="exportData(2)">批量导出</el-button>-->
          </div>
          <div class="text_right" v-if="step != 2">
            <el-button icon="el-icon-delete" size="small" type="danger"
                       @click="deleteListData(immovableList,selectImmovableList,'2')">批量删除
            </el-button>
          </div>
        </div>
        <el-table
            :data="immovableList"
            size="small"
            style="padding-left: 20px"
            height="300px"
            class="table"
            ref="multipleTable2"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeImmovable"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="picMasterViewUrl" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0"
                     class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="文物名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="culturalRelicsCategory" label="文物类别"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="culturalRelicsType" label="文物分类" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.culturalRelicsType ? scope.row.culturalRelicsType : scope.row.other }}
            </template>
          </el-table-column>
          <el-table-column prop="quantity" label="数量" show-overflow-tooltip></el-table-column>
          <el-table-column prop="year" label="年份" show-overflow-tooltip></el-table-column>
          <el-table-column prop="heritageAppraisal" label="现状评估" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("assessment_status", scope.row.heritageAppraisal, '-') }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
    </div>

    <div v-show="step == 0 || step == 2" style="margin-top: 10px">
      <div class="text_center" v-if="step != 2">
        <el-button size="small" @click="returnPage(1)">取消</el-button>
        <el-button size="small" type="primary" @click="nextStep(0)">下一步</el-button>
      </div>
    </div>
    <div v-if="step == 1 || step == 2" style="margin-top: 10px">
      <div class="text_center" v-if="step == 1">
        <el-button size="small" @click="returnPage(1)">取消</el-button>
        <el-button size="small" type="primary" @click="nextStep(1)">上一步</el-button>
        <el-button size="small" type="primary" @click="nextStep(0)">预览</el-button>
      </div>

      <div class="text_center" v-else-if="step == 2 && butType != 1 && butType != 4">
        <el-button size="small" @click="returnPage(1)">取消</el-button>
        <el-button size="small" type="primary" @click="nextStep(1)">上一步</el-button>
        <el-button size="small" type="primary" @click="saveAsDraft(0)">保存为草稿</el-button>
        <el-button size="small" type="primary" @click="saveAsDraft(1)">提交</el-button>
      </div>
      <div class="text_center" v-else>
        <el-button v-if="butType == 4 && !see" size="small" type="primary" @click="switchData(1)">上一条
        </el-button>
        <el-button v-if="butType == 4 && !see" size="small" type="primary" @click="switchData(0)">下一条
        </el-button>
        <el-button size="small" v-if="butType == 4 && see != 1 && this.inputForm.processState == 1"
                   type="primary"
                   @click="through(3)">通过
        </el-button>
        <el-button size="small" v-if="butType == 4 && see != 1 && this.inputForm.processState == 1"
                   type="danger"
                   @click="through(2)">驳回
        </el-button>
        <el-button size="small" @click="returnPage(1)">关闭</el-button>
      </div>
    </div>

    <div v-show="step == 99" style="margin-top: 10px">
      <div class="text_center">
        <el-button size="small" @click="returnPage(1)">关闭</el-button>
      </div>
    </div>

    <add-inventory ref="addInventory"></add-inventory>
    <reviewSuggestions ref="suggestions" @updataStatus="returnPage()"></reviewSuggestions>
  </div>
</template>

<script>
import {Base64} from 'js-base64'
import AddInventory from "@/views/modules/collection/business/collIdentification/addInventory.vue";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";

export default {
  name: "addRepair",
  components: {reviewSuggestions, AddInventory},
  data() {
    return {
      id: '',
      step: 0,
      inputForm: {
        protectEventName: '',
        protectionLevel: '',
        mttr: [],
        cycleStart: '',
        cycleEnd: '',
        protectionType: '',
        protectiveMaterial: '',
        protectiveEquipment: '',
        protectionCost: '',
        protectionPersonnel: '',
        specificProtectionMeasures: '',
        remark: '',
      },
      rules: {
        protectEventName: [
          {required: true, message: '请输入保护事件名称', trigger: 'blur'}
        ],
        protectionCost: [
          {required: true, message: '请输入保护费用', trigger: 'blur'}
        ],
        protectionPersonnel: [
          {required: true, message: '请输入保护人员', trigger: 'blur'}
        ],
        mttr: [
          {type: 'array', required: true, message: '请选择修复时间', trigger: 'blur'}
        ],
      },

      generalList: [],
      selectGeneralList: [],

      auxiliaryList: [],
      selectAuxiliaryList: [],

      immovableList: [],
      selectImmovableList: [],

      registrationSearch: {},

      inputVisible: false,
      inputValue: '',

      inputType: false,
      returnPageStatus: '',
      butType: "", //0新增 1详情 2修改 4审核
      leaveList: [],
      see: false,

      //藏品查看
      dataId: '',
      photoList1: [],
      current1: 1,
      total1: 0,

      photoList2: [],
      current2: 1,
      total2: 0,

      photoList3: [],
      current3: 1,
      total3: 0,

      size: 6,
    }
  },

  mounted() {
    this.id = this.$route.query.id
    this.butType = this.$route.query.butType
    this.see = this.$route.query.see
    this.dataId = this.$route.query.dataId
    if (this.butType == 1 || this.butType == 4) {
      this.inputType = true
      this.step = 2
    }
    this.setPageTitle()

    this.$axios(this.api.collection.listLevelSelect).then(data => {
      if (data.status) {
        this.leaveList = data.data
        if (this.id) {
          this.getRowDetail()
        }
      }
    })
  },

  methods: {
    //0征集藏品 1总账藏品 2辅助帐藏品 3不可移动文物
    addDataList(num, data) {
      this.$refs.addInventory.init(num, data)
    },

    selectionChangeGenera(val) {
      this.selectGeneralList = val
    },

    selectionChangeAuxiliary(val) {
      this.selectAuxiliaryList = val
    },

    selectionChangeImmovable(val) {
      this.selectImmovableList = val
    },

    //删除
    deleteListData(data, selectData,type) {
      if (selectData.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      this.$confirm(`您是否确认删除数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        selectData.forEach(item2 => {
          data.forEach((item1, index) => {
            if (item1.id == item2.id) {
              data.splice(index, 1)
            }
          })
        })
        selectData = []
        this.$refs['multipleTable' + type].clearSelection()
      })
    },

    //导出
    exportData(type) {
      let dataList = []
      if (type == 0) {
        dataList = this.selectGeneralList
      }
      if (type == 1) {
        dataList = this.selectAuxiliaryList
      }
      if (type == 2) {
        dataList = this.selectImmovableList
      }
      if (dataList.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = dataList.map(item => {
        if (item) {
          return item.id
        }
      })
      let data = {
        ids: ids,
        accidentId: this.id,
        type: type,
      }
      this.$refs['multipleTable' + type].clearSelection()
    },

    getRowDetail() {
      this.$axios(this.api.collection.collectionProtectionGetById + this.id, {}, 'get').then(data => {
        if (data.status) {
          this.inputForm = data.data
          this.generalList = data.data.ledgerDatas
          this.auxiliaryList = data.data.auxiliaryDatas
          this.immovableList = data.data.collectionImmovableCulturalRelic
          if (this.inputForm.cycleStart) {
            this.$set(this.inputForm, 'mttr', [this.inputForm.cycleStart, this.inputForm.cycleEnd])
          }
        }
        if (this.butType == 5) {
          this.inputType = true
          this.step = 99
          this.getEnclosure()
        }
      })
    },

    getEnclosure() {
      this.getMountedPictures1()
      this.getMountedPictures2()
      this.getMountedPictures3()
    },

    getMountedPictures1(type) {
      if (type == 1) {
        this.current1--
      }
      if (type == 2) {
        this.current1++
      }
      this.$axios(this.api.collection.collectionProtectionGetMountImgPage, {
        current: this.current1,
        dataId: this.dataId,
        accidentId: this.id,
        size: this.size,
        type: 0,
      }, 'get').then(data => {
        if (data.status) {
          this.photoList1 = data.data.records
          this.total1 = parseInt(data.data.total)
        }
      })
    },

    getMountedPictures2(type) {
      if (type == 1) {
        this.current2--
      }
      if (type == 2) {
        this.current2++
      }
      this.$axios(this.api.collection.collectionProtectionGetMountImgPage, {
        current: this.current2,
        dataId: this.dataId,
        accidentId: this.id,
        size: this.size,
        type: 1,
      }, 'get').then(data => {
        if (data.status) {
          this.photoList2 = data.data.records
          this.total2 = parseInt(data.data.total)
        }
      })
    },

    getMountedPictures3(type) {
      if (type == 1) {
        this.current3--
      }
      if (type == 2) {
        this.current3++
      }
      this.$axios(this.api.collection.collectionProtectionGetMountImgPage, {
        current: this.current3,
        dataId: this.dataId,
        accidentId: this.id,
        size: this.size,
        type: 2,
      }, 'get').then(data => {
        if (data.status) {
          this.photoList3 = data.data.records
          this.total3 = parseInt(data.data.total)
        }
      })
    },

    //预览
    previewPdf(url) {
      this.pdfUrl = url
      window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + encodeURIComponent(Base64.encode(this.pdfUrl)));
    },

    getLeaveList(leave) {
      let data = this.leaveList.filter(item => {
        return item.level == leave
      })
      if (data.length) {
        console.log(data[0].levelName)
        return data[0].levelName
      }
    },

    //0下一步 1上一步
    nextStep(num) {
      if (num == 0) {
        if (this.step == 0) {
          this.$refs.inputForm.validate((valid) => {
            if (valid) {
              this.step++;
            }
          })
        } else {
          if (this.step == 1) {
            // if (this.generalList.length + this.auxiliaryList.length + this.immovableList.length == 0) {
            //     this.$message.error('请至少选择一件藏品')
            //     return
            // }
            this.step++;
            this.inputType = true
          }
        }
      } else {
        if (this.step == 2) {
          this.inputType = false
        }
        this.step--;
      }
    },

    //返回
    returnPage(num) {
      this.returnPageStatus = num
      this.$router.back()
    },

    // type 1提交  0保存为草稿
    saveAsDraft(type) {
      let fechUrl = this.api.collection.collectionProtectionSaveOrUpdateDraft
      let methout = 'post'
      if (type == 1) {
        fechUrl = this.api.collection.collectionProtectionSaveOrUpdateSubmit
        methout = 'post'
      }
      let data = JSON.parse(JSON.stringify(this.inputForm))
      this.$set(data, 'cycleStart', this.inputForm.mttr[0])
      this.$set(data, 'cycleEnd', this.inputForm.mttr[1])
      this.$delete(data, 'mttr')
      let generalLedger = this.generalList.map(item => {
        return item.id
      })
      let subsidiaryAccount = this.auxiliaryList.map(item => {
        return item.id
      })
      let immovableIds = this.immovableList.map(item => {
        return item.id
      })

      this.$axios(fechUrl, {
        ...data,
        processState: type,
        generalLedger: generalLedger,
        subsidiaryAccount: subsidiaryAccount,
        immovableIds: immovableIds,
      }, methout).then(data => {
        if (data.status) {
          this.$message.success(`${type == 0 ? '保存' : '提交'}成功！`)
          this.programExit = true
          this.returnPage()
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    //type 2驳回 3通过
    through(type) {
      this.$refs.suggestions.init(2, [this.inputForm], type, '', this.api.collection.collectionProtectionRevd)
    },

    //1为上一条 0为下一条
    switchData(num) {
      if (Object.keys(this.registrationSearch).length == 0) {
        this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
      }
      if (num == 0) {
        this.registrationSearch.current++
      } else {
        if (this.registrationSearch.current = 1) {
          this.$message.error('已是第一条')
        } else {
          this.registrationSearch.current--
        }
      }
      this.$axios(this.api.collection.exhibitioninfoexamineList, this.registrationSearch, 'get').then((res) => {
        if (res.status) {
          if (res.data && res.data.records.length) {
            this.id = res.data.records[0].id
            this.getRowDetail()
          } else {
            this.registrationSearch.current--
            this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
          }
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    setPageTitle() {
      let name = '保护情况详情'
      if (this.butType == 0) {
        name = '保护情况新增'
      } else if (this.butType == 2) {
        name = '保护情况修改'
      }
      this.$store.state.tagsView.visitedViews.forEach(item => {
        if (item.fullPath === this.$route.fullPath) {
          item.title = name
          return
        }
      })
    },

    jiage(value) {
      // 使用正则表达式来限制输入为正数，并保留两位小数
      value = value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      value = value.replace(/^\./g, ""); //验证第一个字符是数字
      value = value.replace(/\.{2,}/g, "."); //只保留第一个, 清除多余的
      value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两位个小数
      this.inputForm.protectionCost = value
    },

    save2Data() {
      if (!this.inputForm.protectionCost) {
        return
      }
      let menoy = this.inputForm.protectionCost.split('.')
      if (menoy.length != 2) {
        this.inputForm.protectionCost = this.inputForm.protectionCost + '.00'
      } else {
        if (menoy[1].length == 0) {
          this.inputForm.protectionCost = this.inputForm.protectionCost + '00'
        }
        if (menoy[1].length == 1) {
          this.inputForm.protectionCost = this.inputForm.protectionCost + '0'
        }
      }
    },
  },

  //离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (!this.returnPageStatus) {
      sessionStorage.removeItem('listSearch')
      sessionStorage.removeItem('listSearchCollDetail')
    }
    if (this.programExit) {
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
      })
      next();
    } else if (this.butType == 0 || this.butType == 2) {
      this.$confirm("当前内容没有保存，您确定离开吗？", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            //进去别的页面
            if (this.returnPageStatus) {
              this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
              })
            }
            next();
          })
          .catch(() => {
            next(false);
          });
    } else {
      next();
    }
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.minTitle {
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
}

.input-new-tag {
  width: 200px;
}

.input-new-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
}

.adsfaads {
  margin-bottom: 15px !important;
}

.pageHeight {
  height: calc(100vh - 205px);
  overflow-y: auto;
}

.paging {
  margin-top: 12px;
  cursor: pointer;
  font-size: 80px;
  color: #00A0E9;
}

.prohibit {
  cursor: no-drop;
  color: #E4E7ED;
}

.itemStyle {
  width: 16.6%;
  padding: 0px 5px;
}

.itemStyleName {
  font-size: 12px;
}
</style>